




































































	import { Component, Mixins, Prop } from 'vue-property-decorator';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import AppFormBase from '@/components/util/AppBaseForm.vue';
	import { internet } from '@/utils/Internet';
	import { IToken } from '@/model/login/IToken';
	import { IUser } from '@/model/login/IUser';
	import Util from '@/utils/Util';
	import { Icon } from '@/model/util/Icon';
	import { IPayload } from '@/model/login/IPayload';
	import { IPermission } from '@/model/login/IPermission';
	import LoginMixin from '@/mixing/MixinLogin.vue';
	import MainMixing from '@/mixing/MixinMain.vue';
import Keycloak from 'keycloak-js';
	@Component({
		name: 'Login',
		components: {
			AppContainerBase,
			AppFormBase,
		},
	})
	export default class Login extends Mixins(
		LoginMixin,
		MainMixing
	) {
		public isLoading = false;
		public reset = false;
		public show = false;
		public username = '';
		public password = '';
		public isValid = true;
		public form: any = undefined;

		@Prop() readonly keycloak!: Keycloak.KeycloakInstance

		mounted() {
			if(this.getUser === undefined){
				this.getUserAndPerssion()
			}else{
				this.logout()
				this.keycloak.logout()
			}
			this.form = this.$refs.form;
		}
		// public async getData(): Promise<void> {
		// 	await this.form.validate();
		// 	if (this.isValid) {
		// 		this.isLoading = true;
		// 		const data = `grant_type=password&client_id=back_alloagent&client_secret=5cf6da7e-a3f3-4c37-8c4a-42ab7a0ec210&username=${this.username}&password=${this.password}`;
		// 		internet
		// 			.newRequest()
		// 			.post(
		// 				`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/alloxentric/protocol/openid-connect/token`,
		// 				data,
		// 				{
		// 					headers: {
		// 						'Content-Type':
		// 							'application/x-www-form-urlencoded',
		// 					},
		// 				}
		// 			)
		// 			.then((responses) => {
		// 				if (responses.status === 200) {
		// 					const token: IToken = responses.data as IToken;
		// 					this.refreshToken(token);
		// 					const userInfo = internet
		// 						.newRequest()
		// 						.post<IUser>(
		// 							`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/alloxentric/protocol/openid-connect/userinfo`
		// 						);
		// 					const userPermission = internet
		// 						.newRequest()
		// 						.post<IUser>(
		// 							`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/alloxentric/protocol/openid-connect/token`,
		// 							'grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&audience=back_alloagent&response_mode=permissions',
		// 							{
		// 								headers: {
		// 									'Content-Type':
		// 										'application/x-www-form-urlencoded',
		// 								},
		// 							}
		// 						);
		// 					Util.waitForPromises([
		// 						userInfo,
		// 						userPermission,
		// 					])
		// 						.then((responses) => {
		// 							const payload: IPayload = {
		// 								permission: responses[1]
		// 									.data as Array<
		// 									IPermission
		// 								>,
		// 								user: responses[0]
		// 									.data as IUser,
		// 								token,
		// 							};
		// 							Util.showMessage(
		// 								`Bienvenido ${payload.user.name}`,
		// 								Icon.INFO
		// 							);
		// 							this.clearFilter();
		// 							this.login(payload);
		// 							this.$router.push({
		// 								name: 'Menu',
		// 							});
		// 						})
		// 						.catch(console.log)
		// 						.finally(() => {
		// 							console.log(
		// 								'para la casa'
		// 							);
		// 						});
		// 				}

		// 				// console.log(responses.data as IToken);
		// 			})
		// 			.catch(() => {
		// 				Util.showMessage(
		// 					'Credenciales incorrectas',
		// 					Icon.ERROR
		// 				);
		// 			})
		// 			.finally(() => {
		// 				this.isLoading = false;
		// 			});
		// 	}
		// }

		public get rules() {
			return [(v: any) => !!v || 'Cambo es obligatorio'];
		}

		public getUserAndPerssion(){
			//console.log("getUser:", this.getUser)

			const data = `grant_type=refresh_token&client_id=${process.env.VUE_APP_KEYCLOAK_CLIENT}&refresh_token=${this.keycloak.refreshToken}`;
			internet
				.newRequest()
				.post(
					`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
					data,
					{
						headers: {
							'Content-Type':
								'application/x-www-form-urlencoded',
						},
					}
				)
				.then((responses) => {
					if (responses.status === 200) {
						//console.log("responses.data:", responses.data)
						const token: IToken = responses.data as IToken;
						//console.log("token inicial: ", token)
						this.refreshToken(token);
						const userInfo = internet
							.newRequest()
							.post<IUser>(
								`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/userinfo`
							);
						const userPermission = internet
							.newRequest()
							.post<IUser>(
								`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
								`grant_type=urn:ietf:params:oauth:grant-type:uma-ticket&audience=${process.env.VUE_APP_KEYCLOAK_AUDIENCE}&response_mode=permissions`,
								{
									headers: {
										'Content-Type':
											'application/x-www-form-urlencoded',
									},
								}
							);
						Util.waitForPromises([
							userInfo,
							userPermission,
						])
							.then((responses) => {
								//console.log("responses:", responses)
								const payload: IPayload = {
									permission: responses[1]
										.data as Array<
										IPermission
									>,
									user: responses[0]
										.data as IUser,
									token,
								};
								// Util.showMessage(
								// 		`Bienvenido ${payload.user.preferred_username}, Cliente: ${payload.user.groups[0]}` ,
								// 		Icon.INFO
								// 	);
								this.clearFilter();
								this.login(payload);
								this.$router.push({
									name: 'Menu',
								});
							})
							.catch(error => {
								console.log("error:", error)
							})
					}

					// console.log(responses.data as IToken);
				})
				.catch(() => {
					Util.showMessage(
						'Credenciales incorrectas',
						Icon.ERROR
					);
				})
				.finally(() => {
					this.isLoading = false;
				});

		}
	}
